import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { useTranslations } from '../../hooks/use-translations'
import SEO from '../../components/seo'
import NewsSection from '../../components/NewsSection'
import Button from '../../components/button/button'
import ContactForm from '../../components/contact-form'
import * as s from '../../pages/news.module.scss'

const EventPage: React.FC = () => {
  const { t } = useTranslations()
  const [modal, setModal] = React.useState(false)
  const description = 'Клуб цифрового строительства'

  return (
    <>
      <SEO title="DCC" description={description} />
      <NewsSection
        title="Клуб цифрового строительства"
        dateData={{
          date: '17.12.2021',
          event: 'Cобытие',
        }}
      >
        <p className={s.text}>
          На площадке SIGNAL впервые прошел Клуб цифрового строительства, в
          рамках которого собрались BIM-менеджеры ведущих Российских
          девелоперов: МР Групп, Галс, Донстрой, Самолет, ФСК, А101, Интеко,
          Level Group, Пионер, СБ Девелопмент и других.
        </p>
        <p className={s.text}>
          Одна из главных целей Клуба - разработка единого универсального
          BIM-стандарта для девелоперских проектов в России. Если Вы хотите
          принять участие в его целеполагании и разработке - присоединяйтесь!
        </p>
        <div className="flex justify-between flex-wrap">
          <StaticImage
            width={539}
            className={s.imageBlock}
            src="../../assets/images/news/2021_12_17_ev_dcc/image-1.png"
            alt={description}
            title={description}
            placeholder="blurred"
          />
          <StaticImage
            width={539}
            className={s.imageBlock}
            src="../../assets/images/news/2021_12_17_ev_dcc/image-2.png"
            alt={`${description} 2`}
            title={`${description} 2`}
            placeholder="blurred"
          />
          <StaticImage
            width={539}
            className={s.imageBlock}
            src="../../assets/images/news/2021_12_17_ev_dcc/image-3.png"
            alt={`${description} 3`}
            title={`${description} 3`}
            placeholder="blurred"
          />
          <StaticImage
            width={539}
            className={s.imageBlock}
            src="../../assets/images/news/2021_12_17_ev_dcc/image-4.png"
            alt={`${description} 4`}
            title={`${description} 4`}
            placeholder="blurred"
          />
        </div>
        <div className="flex">
          <StaticImage
            className={s.imageBlock}
            src="../../assets/images/news/2021_12_17_ev_dcc/image-5.png"
            alt={`${description} 5`}
            title={`${description} 5`}
            placeholder="blurred"
          />
        </div>
        <p className={`flex justify-center ${s.text}`}>Заполните форму ниже</p>
        <div className="flex justify-center">
          <Button className="my-4" onClick={() => setModal(true)}>
            <span className="my-1">{t('Submit your application')}</span>
          </Button>
        </div>
        {modal && (
          <ContactForm
            onClose={() => setModal(false)}
            title="Заявка на участие в разработке стандарта"
            requestType="Digital Construction Club"
          />
        )}
      </NewsSection>
    </>
  )
}

export default EventPage
